
import React from "react";
import { useTranslation } from "react-i18next";
import { FaqAccordion } from "../FaqAccordion";
import style from "./style.module.scss"
/*
    {
        
    }
*/
// export const CheckAndHideFooter = () => {
//     const notifExist = localStorage.getItem('notif')
//     const ftElement = document.querySelector('#preloader');
//     if(notifExist || !notifExist) {
//         if (ftElement) { 
//             ftElement.style.height = '100%';
//         }

//     }
// }

export function FaqSection() {
    // React.useEffect(() => {
    //     CheckAndHideFooter()
    // }, [])

    // var dc = document.querySelector('.ftMobile') 
    const { t } = useTranslation("translations")
    const questions = [
        {
            title: t("faq-page.q-1.title"),
            description: <>
                {t("faq-page.q-1.description-1")}
                <ul>
                    <li>{t("faq-page.q-1.description-2")}</li>
                    <li>{t("faq-page.q-1.description-3")}</li>
                    <li>{t("faq-page.q-1.description-4")}</li>
                </ul>

            </>
        },
        {
            title: t("faq-page.q-2.title"),
            description: t("faq-page.q-2.description")
        },
        {
            title: t("faq-page.q-3.title"),
            description: <>
                {t("faq-page.q-3.description-1")}

                <ul>
                    <li>
                        {t("faq-page.q-3.description-2")}

                    </li>
                    <li>
                        {t("faq-page.q-3.description-3")}

                    </li>
                    <li>
                        {t("faq-page.q-3.description-4")}

                    </li>
                    <li>
                        {t("faq-page.q-3.description-5")}

                    </li>
                    <li>
                        {t("faq-page.q-3.description-6")}

                    </li>
                    <li>
                        {t("faq-page.q-3.description-7")}

                    </li>
                    <li>
                        {t("faq-page.q-3.description-8")}

                    </li>
                </ul>
            </>
        },
        {
            title: t("faq-page.q-4.title"),
            description: t("faq-page.q-4.description")
        },
        {
            title: t("faq-page.q-5.title"),
            description: t("faq-page.q-5.description")
        },
        {
            title: t("faq-page.q-6.title"),
            description: t("faq-page.q-6.description")
        },
        {
            title: t("faq-page.q-7.title"),
            description: <>
                {t("faq-page.q-7.description-1")}

                <ul>
                    <li>
                        {t("faq-page.q-7.description-2")}

                    </li>
                    <li>
                        {t("faq-page.q-7.description-3")}


                    </li>
                    <li>
                        {t("faq-page.q-7.description-4")}

                    </li>
                </ul>
                <b>
                    {t("faq-page.q-7.description-5")}

                </b>
                {t("faq-page.q-7.description-6")}


            </>
        },
        {
            title: t("faq-page.q-8.title"),
            description: t("faq-page.q-8.description")
        },
        {
            title: t("faq-page.q-9.title"),
            description: t("faq-page.q-9.description")
        },
        {
            title: t("faq-page.q-10.title"),
            description: t("faq-page.q-10.description")
        }, {
            title: t("faq-page.q-11.title"),
            description: t("faq-page.q-11.description")
        },
        {
            title: t("faq-page.q-12.title"),
            description: t("faq-page.q-12.description")
        },
        {
            title: t("faq-page.q-13.title"),
            description: <>
                {t("faq-page.q-13.description-1")}

                <ul>
                    <li>{t("faq-page.q-13.description-2")}</li>
                    <li>{t("faq-page.q-13.description-3")}</li>
                    <li>{t("faq-page.q-13.description-4")}</li>
                    <li>{t("faq-page.q-13.description-5")}</li>
                    <li>{t("faq-page.q-13.description-6")}</li>
                    <li>{t("faq-page.q-13.description-7")}</li>
                    <li>{t("faq-page.q-13.description-8")}</li>
                    <li>{t("faq-page.q-13.description-9")}</li>
                    <li>{t("faq-page.q-13.description-10")}</li>
                    <li>{t("faq-page.q-13.description-11")}</li>
                    <li>{t("faq-page.q-13.description-12")}</li>
                    <li>{t("faq-page.q-13.description-13")}</li>
                    <li>{t("faq-page.q-13.description-14")}</li>
                    <li>{t("faq-page.q-13.description-15")}</li>
                    <li>{t("faq-page.q-13.description-16")}</li>
                    <li>{t("faq-page.q-13.description-17")}</li>
                    <li>{t("faq-page.q-13.description-18")}</li>
                    <li>{t("faq-page.q-13.description-19")}</li>
                    <li>{t("faq-page.q-13.description-20")}</li>
                    <li>{t("faq-page.q-13.description-21")}</li>
                    <li>{t("faq-page.q-13.description-22")}</li>
                    <li>{t("faq-page.q-13.description-23")}</li>
                    <li>{t("faq-page.q-13.description-24")}</li>
                    <li>{t("faq-page.q-13.description-25")}</li>
                    <li>{t("faq-page.q-13.description-26")}</li>
                    <li>{t("faq-page.q-13.description-27")}</li>
                    <li>{t("faq-page.q-13.description-28")}</li>
                    <li>{t("faq-page.q-13.description-29")}</li>
                    <li>{t("faq-page.q-13.description-30")}</li>
                    <li>{t("faq-page.q-13.description-31")}</li>
                    <li>{t("faq-page.q-13.description-32")}</li>
                    <li>{t("faq-page.q-13.description-33")}</li>
                    <li>{t("faq-page.q-13.description-34")}</li>
                    <li>{t("faq-page.q-13.description-35")}</li>
                    <li>{t("faq-page.q-13.description-36")}</li>
                    <li>{t("faq-page.q-13.description-37")}</li>
                    <li>{t("faq-page.q-13.description-38")}</li>
                    <li>{t("faq-page.q-13.description-39")}</li>
                    <li>{t("faq-page.q-13.description-40")}</li>
                    <li>{t("faq-page.q-13.description-41")}</li>
                    <li>{t("faq-page.q-13.description-42")}</li>
                    <li>{t("faq-page.q-13.description-43")}</li>
                    <li>{t("faq-page.q-13.description-44")}</li>
                </ul>
            </>
        },
        {
            title: t("faq-page.q-14.title"),
            description: t("faq-page.q-14.description")
        },
        {
            title: t("faq-page.q-15.title"),
            description: <>
                {t("faq-page.q-15.description-1")}

                <b>{t("faq-page.q-15.description-2")}</b>
                <ul>
                    <li>{t("faq-page.q-15.description-3")}</li>
                    <li>{t("faq-page.q-15.description-4")}</li>
                    <li>{t("faq-page.q-15.description-5")}</li>
                </ul>

                <b>{t("faq-page.q-15.description-6")}</b>
                <ul>
                    <li>{t("faq-page.q-15.description-7")}</li>
                    <li>{t("faq-page.q-15.description-8")}</li>
                </ul>

                <b>{t("faq-page.q-15.description-9")}</b>
            </>
        }

    ]
    const [index, setIndex] = React.useState(null)
    const handleOpen = (i) => {
        setIndex(prev => i === prev ? null : i)
    }
    return (
        <>
            <section>
                <div className={style.section}>
                    <div className={style.sectionContainer}>
                        {
                            questions.map((q, i) => (
                                <FaqAccordion
                                    key={i}
                                    title={q.title}
                                    isOpen={i === index}
                                    onOpen={() => handleOpen(i)}
                                >
                                    {q.description}
                                </FaqAccordion>
                            ))
                        }
                    </div>

                </div>
            </section>
        </>
    )
}